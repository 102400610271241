import { Route, Routes } from 'react-router-dom';
import './App.css';
import SearchPage from './component/barcodesearch';
import CampForm from './component/registration';
function App() {
  return (
    <div className="App">
     <Routes>
     
       <Route path='/' element={<CampForm/>} />
       <Route path='/search' element={<SearchPage/>} />
       </Routes>
    </div>
  );
}

export default App;
