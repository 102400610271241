import axios from "axios";
import React, { useState } from "react";
import logoImg from '../images/logo.png';
import "./CampForm.css"; // Import CSS file for styling
const CampForm = () => {
  const [formData, setFormData] = useState({
    patientName: "",
    initials: "",
    mobile: "",
    email: "",
    gender: "",
    age: "",
    edta: "",
    serum: "",
    report: "",
    campsource: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://api.labridge.in:5011/camp/patientregister", formData);
      alert("Camp details submitted successfully!");
      // Clear form after successful submission
      setFormData({
        patientName: "",
        initials: "",
        mobile: "",
        email: "",
        gender: "",
        age: "",
        edta: "",
        serum: "",
        report: "",
        campsource: "Metro Brand"
      });
    } catch (error) {
      console.error("Error submitting camp details:", error);
      alert("Failed to submit camp details. Please try again later.");
    }
  };

  return (<>
  <nav className="navbar bg-body-tertiary d-flex justify-content-center align-items-center">
 
  <div className="">
    
    <a className="navbar-brand" href="#">
    <img className="logo-img" src={logoImg} alt=""  width={150}
        height={55} />
    </a>
    
  </div>
 
</nav>

  
    <div className="container1">
     <center> <h2 className="mt-4 mb-4"> Registration Details</h2></center>
      <form onSubmit={handleSubmit}>
      <div className="form-outline">
      <label  className="form-label" htmlFor="form10Example1">Title:</label>
          <select
         
           className="select form-control"
           id="form10Example3"
            name="initials"
            value={formData.initials}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Miss">Miss</option>
            <option value="Master">Master</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Baby.">Baby.</option>
            <option value="Daughter of">Daughter of</option>
            <option value="Son of">Son of</option>
          </select>
        </div>
        <div className="form-outline mt-2">
        <label className="form-label" htmlFor="form10Example1">
                  Patient Name*
                </label>
          <input
             type="text"
             id="form10Example1"
             className="form-control"
            name="patientName"
            value={formData.patientName}
            onChange={handleChange}
            required
          />
        </div>
   
        <div className="form-outline mt-2">
          <label  className="form-label" htmlFor="form10Example1">Mobile:</label>
          <input
            type="Number"
            id="form10Example1"
            className="form-control"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-outline mt-2">
          <label  className="form-label" htmlFor="form10Example1">Email:</label>
          <input
            type="email"
            name="email"
            id="form10Example1"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-outline mt-2">
          <label  className="form-label" htmlFor="form10Example1">Gender:</label>
          <select  className="select form-control"
                  id="form10Example3" name="gender" value={formData.gender} onChange={handleChange} required>
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-outline mt-2">
          <label  className="form-label" htmlFor="form10Example1">Age:</label>
          <input
           id="form10Example1"
           className="form-control"
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-outline mt-2">
          <label  className="form-label" htmlFor="form10Example1">EDTA:</label>
          <input
           id="form10Example1"
           className="form-control"
            type="text"
            name="edta"
            value={formData.edta}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-outline mt-2">
          <label  className="form-label" htmlFor="form10Example1">Serum:</label>
          <input
           id="form10Example1"
           className="form-control"
            type="text"
            name="serum"
            value={formData.serum}
            onChange={handleChange}
            required
          />
        </div>
    
        <button className="btn btn-success mt-2" type="submi">Submit</button>
      </form>
    </div>
    </>
  );
};

export default CampForm;
