// SearchPage.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./SearchPage.css"; // Import CSS file for styling

const SearchPage = () => {
  const [edta, setEdta] = useState("");
  const [serum, setSerum] = useState("");
//   const [edtaRecords, setEdtaRecords] = useState([]);
//   const [serumRecords, setSerumRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const handleSearchEdta = async (edta) => {
    try {
      const response = await axios.post("https://api.labridge.in:5011/camp/search/edta", { edta });
      setSelectedPatient(response.data);
      setEdta(response.data[0].edta);
      setSerum(response.data[0].serum);

    } catch (error) {
      console.error("Error searching records by EDTA:", error);
    }
  };

  const handleSearchSerum = async (serum) => {
    try {
      const response = await axios.post("https://api.labridge.in:5011/camp/search/serum", { serum });
      setSelectedPatient(response.data);
      setEdta(response.data[0].edta);
      setSerum(response.data[0].serum);
     
    } catch (error) {
      console.error("Error searching records by Serum:", error);
    }
  };
  const handleSelectSuggestion = async (id) => {
    const response = await axios.get(`https://api.labridge.in:5011/camp/patientDetails/${id}`);
    setSelectedPatient([response.data]);
    setEdta(response.data.edta);
      setSerum(response.data.serum);
      if(response.data.length){
        alert("Two entries with same barcode")
      }
    setSuggestions([]);
  };
  const handleUpdate = async () => {
    try {
      const response = await axios.put(`https://api.labridge.in:5011/camp/updatePatientSample/${selectedPatient[0]._id}`, { edta, serum });
      alert('Sample details updated successfully!');
      // Optionally, refresh the patient details to show the update
     
    } catch (error) {
      console.error('Error updating sample details:', error);
      alert('Failed to update sample details. Please try again later.');
    }
  };
  useEffect(() => {
    const loadSuggestions = async () => {
      if (searchTerm.trim() !== '') {
        const response = await axios.get(`https://api.labridge.in:5011/camp/search/patient?name=${searchTerm}`);
        setSuggestions(response.data);
      } else {
        setSuggestions([]);
      }
    };

    loadSuggestions();
  }, [searchTerm]);
  return (
    <div className="container">
      <h1>Search Records</h1>
      <div className="search-form">
        <div className="input-group">
          <label>EDTA:</label>
          <input type="text"  onChange={(e) => handleSearchEdta(e.target.value)} />
          
        </div>
        <div className="input-group">
          <label>Serum:</label>
          <input type="text"  onChange={(e) => handleSearchSerum(e.target.value)} />
       
        </div>
        <div>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search patient name..."
      />
      <div>
        {suggestions.map((suggestion) => (
          <div key={suggestion._id} onClick={() => handleSelectSuggestion(suggestion._id)}>
            {suggestion.patientName}
          </div>
        ))}
      </div>
     
    </div>
      </div>
  { selectedPatient.length>0 ?  <div className="search-results">
  <h2>Search Results:</h2>
  <div className="results-section">
 
  
      <div  className="record-card">
        <div className="record-title">Patient Name: {selectedPatient[0].patientName}</div>
        <div className="record-info">Age: {selectedPatient[0].age}</div>
        <div className="d-flex flex-column">
        <label>EDTA:</label>
        <input type="text" value={edta} onChange={(e)=>{setEdta(e.target.value)}} className="record-info"/>
        <label>SERUM:</label>
        <input type="text" value={serum} onChange={(e)=>{setSerum(e.target.value)}} className="record-info"/>
        </div>
        <button className="btn btn-success" onClick={handleUpdate}>Update</button>
      </div>

  </div>

</div>:<></>}
    </div>
  );
};

export default SearchPage;
